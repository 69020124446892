<template>
  <main>
    <div class="guest-layout">
      <slot />
    </div>
    <Footer />
  </main>
</template>

<script>
import Footer from '@/components/app/Footer';

export default {
  name: 'GuestLayout',
  components: { Footer },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

main {
  background-color: $footer;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 20px;
  grid-template-areas:
    'content'
    'footer';

  .guest-layout {
    width: 100%;
    grid-area: content;
  }
}
</style>
